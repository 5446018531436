document.addEventListener("turbolinks:load", function() {
  const searchInput = document.getElementById('search-input');
  const resultsContainer = document.getElementById('search-results');
  const orderIdElement = document.getElementById('order-id');
  let orderId = orderIdElement ? orderIdElement.dataset.orderId : null;

  let timeout = null;

  if (searchInput) {
    searchInput.addEventListener('input', function() {
      const query = searchInput.value.trim();

      clearTimeout(timeout);

      if (query.length >= 3 && orderId) {
        timeout = setTimeout(() => {
          fetch(`/v2/orders/${orderId}/assigned_orders/search_units?query=${query}`, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          })
          .then(response => {
            return response.json();
          })
          .then(data => {
            displaySearchResults(data, orderId, resultsContainer);
          })
          .catch(error => {
            resultsContainer.innerHTML = '<div class="p-4 bg-white shadow rounded">Error fetching results</div>';
          });
        }, 300);
      } else {
        resultsContainer.innerHTML = '';
      }
    });
  }

  function displaySearchResults(data, orderId, container) {
    container.innerHTML = '';
    data.forEach(unit => {
      const unitElement = document.createElement('div');
      unitElement.id = 'unit-' + unit.id;
      unitElement.classList.add('p-4', 'bg-white', 'shadow', 'rounded', 'mb-2', 'flex', 'flex-col');
      unitElement.innerHTML = `
        <div class="font-bold">${unit.identifier} - ${unit.name}</div>
        <div class="text-gray-700">${unit.add_str}</div>
        <div class="receipt-status">
          ${unit.receipt ? (unit.status === 'Provisional' ? `<div class="mt-2 text-sm text-red-500">Unit Received - Provisional</div>` : `<div class="mt-2 text-sm text-green-500">Unit Received - Valid</div>`) : ''}
        </div>
      `;

      const buttonsContainer = document.createElement('div');
      buttonsContainer.classList.add('mt-2', 'flex', 'justify-start', 'space-x-2');

      if (unit.status === 'Provisional') {
        const validateReceiptButton = document.createElement('button');
        validateReceiptButton.textContent = 'Validate Receipt';
        validateReceiptButton.classList.add('bg-yellow-500', 'text-white', 'px-3', 'py-1', 'text-sm', 'rounded');
        validateReceiptButton.onclick = () => replaceUnitReceipt(orderId, unit.id, unitElement);
        buttonsContainer.appendChild(validateReceiptButton);
      } else if (!unit.receipt) {
        const markReceivedButton = document.createElement('button');
        markReceivedButton.textContent = 'Mark as Received';
        markReceivedButton.classList.add('bg-red-500', 'text-white', 'px-3', 'py-1', 'text-sm', 'rounded');
        markReceivedButton.onclick = () => markUnitAsReceived(orderId, unit.id, unitElement);
        buttonsContainer.appendChild(markReceivedButton);
      }

      unitElement.appendChild(buttonsContainer);
      container.appendChild(unitElement);
    });
  }

  function markUnitAsReceived(orderId, unitId, unitElement) {
    fetch(`/v2/orders/${orderId}/assigned_orders/${unitId}/create_unit_receipt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      const receiptStatus = unitElement.querySelector('.receipt-status');
      receiptStatus.innerHTML = '<div class="mt-2 text-sm text-green-500">Unit Received - Valid</div>';
      const buttonsContainer = unitElement.querySelector('div.mt-2.flex.justify-start.space-x-2');
      buttonsContainer.innerHTML = '';

      // Update quorum information
      const validBallotsReceived = document.getElementById('valid-ballots-received');
      const quorumMetElement = document.getElementById('quorum-met');

      if (validBallotsReceived && quorumMetElement) {
        validBallotsReceived.textContent = data.units_received_valid;
        quorumMetElement.textContent = data.quorum_met ? 'Yes' : 'No';
        quorumMetElement.classList.remove(data.quorum_met ? 'text-red-500' : 'text-green-500');
        quorumMetElement.classList.add(data.quorum_met ? 'text-green-500' : 'text-red-500');
      }
    })
    .catch(error => {
      alert('Failed to create receipt');
    });
  }

  function replaceUnitReceipt(orderId, unitId, unitElement) {
    fetch(`/v2/orders/${orderId}/assigned_orders/${unitId}/replace_unit_receipt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      const receiptStatus = unitElement.querySelector('.receipt-status');
      receiptStatus.innerHTML = '<div class="mt-2 text-sm text-green-500">Unit Received - Valid</div>';
      const buttonsContainer = unitElement.querySelector('div.mt-2.flex.justify-start.space-x-2');
      buttonsContainer.innerHTML = '';

      // Update quorum information
      const validBallotsReceived = document.getElementById('valid-ballots-received');
      const quorumMetElement = document.getElementById('quorum-met');

      if (validBallotsReceived && quorumMetElement) {
        validBallotsReceived.textContent = data.units_received_valid;
        quorumMetElement.textContent = data.quorum_met ? 'Yes' : 'No';
        quorumMetElement.classList.remove(data.quorum_met ? 'text-red-500' : 'text-green-500');
        quorumMetElement.classList.add(data.quorum_met ? 'text-green-500' : 'text-red-500');
      }
    })
    .catch(error => {
      alert('Failed to validate receipt');
    });
  }
});
