document.addEventListener("DOMContentLoaded", function() {
    const form = document.getElementById('inspectorForm');

    if (form) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let hasSubmitted = false;

      function validateForm() {
        const nameField = form.querySelector('[name="potential_election_inspector[name]"]');
        const emailField = form.querySelector('[name="potential_election_inspector[email]"]');
        const cityField = form.querySelector('[name="potential_election_inspector[city]"]');
        const drivingDistanceField = form.querySelector('[name="potential_election_inspector[driving_distance]"]');
        const commissionNumberField = form.querySelector('[name="potential_election_inspector[commission_number]"]');
        const isEmailValid = emailRegex.test(emailField.value);
        const isFormValid = nameField.value && emailField.value && cityField.value && drivingDistanceField.value && commissionNumberField.value && isEmailValid;

        if (hasSubmitted) {
          if (!nameField.value) showErrorMessage(nameField, 'Name is required');
          else clearErrorMessage(nameField);

          if (!emailField.value) showErrorMessage(emailField, 'Email is required');
          else if (!isEmailValid) showErrorMessage(emailField, 'Email must be a valid email address');
          else clearErrorMessage(emailField);

          if (!cityField.value) showErrorMessage(cityField, 'City is required');
          else clearErrorMessage(cityField);

          if (!drivingDistanceField.value) showErrorMessage(drivingDistanceField, 'Driving distance is required');
          else clearErrorMessage(drivingDistanceField);

          if (!commissionNumberField.value) showErrorMessage(commissionNumberField, 'Commission number is required');
          else clearErrorMessage(commissionNumberField);
        }

        return isFormValid;
      }

      function showErrorMessage(field, message) {
        let errorElement = field.nextElementSibling;
        if (!errorElement || !errorElement.classList.contains('error-message')) {
          errorElement = document.createElement('div');
          errorElement.classList.add('error-message', 'text-red-500', 'text-sm', 'mt-2');
          field.parentNode.insertBefore(errorElement, field.nextSibling);
        }
        errorElement.textContent = message;
      }

      function clearErrorMessage(field) {
        const errorElement = field.nextElementSibling;
        if (errorElement && errorElement.classList.contains('error-message')) {
          errorElement.remove();
        }
      }

      form.addEventListener('input', function(event) {
        if (hasSubmitted) {
          clearErrorMessage(event.target);
          validateForm();
        }
      });

      form.addEventListener('submit', function(event) {
        hasSubmitted = true;
        if (!validateForm()) {
          event.preventDefault();
        }
      });

      // Check for flash messages and display them as error messages
      const flashAlert = document.querySelector('.flash-alert');
      if (flashAlert) {
        const commissionNumberField = form.querySelector('[name="potential_election_inspector[commission_number]"]');
        showErrorMessage(commissionNumberField, flashAlert.textContent);
      }

      validateForm();
    }
  });
